export interface EnvironmentConfig {
    production: boolean;
    envName: string;
    apiBaseUrl: string;
    tokenEndpoint: string;
    clientKey: string;
    clientSecret: string;
    clientId: string;
    tenant: string;
  }

  export interface Environments {
    [key:string]: EnvironmentConfig;
  }

  export const environments: Environments = {
    local: {
      production: false,
      envName: 'local',
      apiBaseUrl: 'https://bet-api-dev.duke-energy.app',
      tokenEndpoint:
        'https://login.microsoftonline.com/2ede383a-7e1f-4357-a846-85886b2c0c4d/',
      clientKey: '---',
      clientSecret: '---',
      clientId: '56ee9ba1-f295-48db-814a-dc1b594cac14',
      tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
    },
    sbx: {
      production: false,
      envName: 'sbx',
      apiBaseUrl: 'https://bet-api-sbx.duke-energy.app',
      tokenEndpoint:
        'https://login.microsoftonline.com/2ede383a-7e1f-4357-a846-85886b2c0c4d/',
      clientKey: '---',
      clientSecret: '---',
      clientId: '56ee9ba1-f295-48db-814a-dc1b594cac14',
      tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
    },
    dev: {
      production: false,
      envName: 'dev',
      apiBaseUrl: 'https://bet-api-dev.duke-energy.app',
      tokenEndpoint:
        'https://login.microsoftonline.com/2ede383a-7e1f-4357-a846-85886b2c0c4d/',
      clientKey: '---',
      clientSecret: '---',
      clientId: '56ee9ba1-f295-48db-814a-dc1b594cac14',
      tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
    },
    qa: {
      production: false,
      envName: 'qa',
      apiBaseUrl: 'https://bet-api-qa.duke-energy.app',
      tokenEndpoint:
        'https://login.microsoftonline.com/2ede383a-7e1f-4357-a846-85886b2c0c4d/',
      clientKey: '---',
      clientSecret: '---',
      clientId: '6fe0acc9-3e53-48a2-a2dc-2a98557e7bea',
      tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
    },
    prod: {
      production: true,
      envName: 'prod',
      apiBaseUrl: 'https://bet-api.duke-energy.app',
      tokenEndpoint:
        'https://login.microsoftonline.com/2ede383a-7e1f-4357-a846-85886b2c0c4d/',
      clientKey: '---',
      clientSecret: '---',
      clientId: '88e7ac06-343e-4528-aa4b-1e9bb1b2ebf5',
      tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
    },
  };
