import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SearchComponent} from "../main/search/search.component";
import {NgOptimizedImage} from "@angular/common";
import {EdsSvgComponent} from "@electronds/ng-components-duke";

@Component({
  selector: 'app-header',
  standalone: true,
    imports: [
        SearchComponent,
        NgOptimizedImage,
        EdsSvgComponent
    ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, AfterViewInit {
    svgPath = "./assets/icons/duke-energy-white.svg";
    ngOnInit(): void {

    }
    ngAfterViewInit(): void {
    }
}
