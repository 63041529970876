import { Routes } from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {AppComponent} from "./app.component";
import {AuthComponent} from "./components/auth/auth.component";

export const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        component: AppComponent,
      }
    ]
  },
];
