import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { env } from '../../config/env';
import {EnvironmentConfig, environments} from '../../config/env.config';

@Injectable()
export class AppConfig {
  private config: EnvironmentConfig = {} as EnvironmentConfig;
  currentEnvironment: string = env.env;
  environmentConfigs = environments;
  constructor(private http: HttpClient) {}
  /*
   * Use to get the data found in the second file (config file)
   */
  // public getConfig<K extends keyof EnvironmentConfig>(key: K): EnvironmentConfig[K] {
  //   return this.config[key];
  // }

  public getApiBaseUrl(): string {
    return this.config.apiBaseUrl;
  }

  public load(): void {
    if (this.environmentConfigs.hasOwnProperty(this.currentEnvironment)) {
      this.config = this.environmentConfigs[this.currentEnvironment] ;
    }
  }
}
