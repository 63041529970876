import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { ILoggerCallback } from '@azure/msal-common';
import {environments} from "../../../config/env.config";
import {env} from "../../../config/env";


const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  const config = environments[env.env];
  return new PublicClientApplication({
    auth: {
      clientId: config.clientId,
      authority:
        'https://login.microsoftonline.com/' +
        config.tenant,
      redirectUri: window.location.origin,
      //postLogoutRedirectUri: environments[env.env].unauthorizedRoute
      postLogoutRedirectUri: 'http://localhost:4200'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  /* protectedResourceMap.set(environments[env.env].apiBaseUrl, [
    `api://${environments[env.env].azureClientId}/Assets.Read`
  ]); */
  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', [
  //   'user.read'
  // ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    //loginFailedRoute: environments[env.env].unauthorized
    loginFailedRoute: window.location.origin
  };
}

export const loggerCallback: ILoggerCallback = (
  level: number,
  message: string
) => {
  // console.log(`From MSAL: ${message}`);
};
