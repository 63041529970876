import { Injectable, Inject } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse,
} from "@angular/common/http";
import {
  AccountInfo,
  AuthenticationResult,
  BrowserConfigurationAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import {Observable, EMPTY, of, throwError} from "rxjs";
import { switchMap, catchError, take, filter } from "rxjs/operators";


import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService, MsalInterceptorAuthRequest,
  MsalInterceptorConfiguration,
  MsalService
} from "@azure/msal-angular";

@Injectable()
export class BetInterceptor implements HttpInterceptor {
  constructor(
      @Inject(MSAL_INTERCEPTOR_CONFIG)
      private msalInterceptorConfig: MsalInterceptorConfiguration,
      private authService: MsalService,
      private msalBroadcastService: MsalBroadcastService
  ) {}

  intercept(
      req: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
        this.msalInterceptorConfig.interactionType !== InteractionType.Popup &&
        this.msalInterceptorConfig.interactionType !== InteractionType.Redirect
    ) {
      throw new BrowserConfigurationAuthError(
          "invalid_interaction_type",
          "Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, InteractionType.Redirect must be provided in the msalInterceptorConfiguration"
      );
    }

    this.authService.getLogger().verbose("MSAL Interceptor activated");
    const scopes = ['user.read'];

    // If no scopes for endpoint, does not acquire token
    if (!scopes || scopes.length === 0) {
      this.authService
          .getLogger()
          .verbose("Interceptor - no scopes for endpoint");
      return next.handle(req);
    }

    // Sets account as active account or first account
    let account: any;
    if (!!this.authService.instance.getActiveAccount()) {
      this.authService
          .getLogger()
          .verbose("Interceptor - active account selected");
      account = this.authService.instance.getActiveAccount();
    } else {
      this.authService
          .getLogger()
          .verbose("Interceptor - no active account, fallback to first account");
      account = this.authService.instance.getAllAccounts()[0];
    }

    const authRequest =
        typeof this.msalInterceptorConfig.authRequest === "function"
            ? this.msalInterceptorConfig.authRequest(this.authService, req, {
              account: account,
            })
            : { ...this.msalInterceptorConfig.authRequest, account };

    this.authService
        .getLogger()
        .info(`Interceptor - ${scopes.length} scopes found for endpoint`);
    this.authService
        .getLogger()
        .infoPii(`Interceptor - [${scopes}] scopes found for ${req.url}`);

    return this.acquireToken(authRequest, scopes, account).pipe(
        switchMap((result: AuthenticationResult) => {
          this.authService
              .getLogger()
              .verbose("Interceptor - setting authorization headers");
          const headers = req.headers.set(
              "Authorization",
              `Bearer ${result.idToken}`
          );

          const requestClone = req.clone({ headers });
          return this.handleRequest(requestClone, next);
        })
    );
  }
    private handleRequest(request: HttpRequest<unknown>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                switch (err.status) {
                    case 400:
                        console.log('Bad Request');
                        break;
                    case 401:
                        console.log('Unauthorized');
                        break;
                    case 403:
                        console.log('Forbidden');
                        break;
                    case 404:
                        console.log('Not Found');
                        break;
                    case 500:
                        console.log('Internal Server Error');
                        break;
                    case 501:
                        console.log('Not Implemented');
                        break;
                    case 503:
                        console.log('Service Unavailable');
                        break;
                    default:
                        console.log('Request Failed/No Response');
                        break;
                }
                return throwError(err);
            })
        );
    }
  /**
   * Try to acquire token silently. Invoke interaction if acquireTokenSilent rejected with error or resolved with null access token
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @param account Account
   * @returns Authentication result
   */
  private acquireToken(
      authRequest: MsalInterceptorAuthRequest,
      scopes: string[],
      account: AccountInfo
  ): Observable<AuthenticationResult> {
    // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
    return this.authService
        .acquireTokenSilent({ ...authRequest, scopes, account })
        .pipe(
            catchError(() => {
              this.authService
                  .getLogger()
                  .error(
                      "Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve."
                  );
              return this.msalBroadcastService.inProgress$.pipe(
                  take(1),
                  switchMap((status: InteractionStatus) => {
                    if (status === InteractionStatus.None) {
                      return this.acquireTokenInteractively(authRequest, scopes);
                    }

                    return this.msalBroadcastService.inProgress$.pipe(
                        filter(
                            (status: InteractionStatus) =>
                                status === InteractionStatus.None
                        ),
                        take(1),
                        switchMap(() => this.acquireToken(authRequest, scopes, account))
                    );
                  })
              );
            }),
            switchMap((result: AuthenticationResult) => {
              if (!result.accessToken) {
                this.authService
                    .getLogger()
                    .error(
                        "Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve."
                    );
                return this.msalBroadcastService.inProgress$.pipe(
                    filter(
                        (status: InteractionStatus) => status === InteractionStatus.None
                    ),
                    take(1),
                    switchMap(() =>
                        this.acquireTokenInteractively(authRequest, scopes)
                    )
                );
              }
              return of(result);
            })
        );
  }

  private acquireTokenInteractively(
      authRequest: MsalInterceptorAuthRequest,
      scopes: string[]
  ): Observable<AuthenticationResult> {
    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
      this.authService
          .getLogger()
          .verbose(
              "Interceptor - error acquiring token silently, acquiring by popup"
          );
      return this.authService.acquireTokenPopup({ ...authRequest, scopes });
    }
    this.authService
        .getLogger()
        .verbose(
            "Interceptor - error acquiring token silently, acquiring by redirect"
        );
    const redirectStartPage = window.location.href;
    this.authService.acquireTokenRedirect({
      ...authRequest,
      scopes,
      redirectStartPage,
    });
    return EMPTY;
  }
}