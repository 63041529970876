<div class="container">
    <header class="header">
        <app-header></app-header>
    </header>
    <div class="search">
        <app-search></app-search>
    </div>
    <main class="main">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </main>
    <footer class="footer">
        <app-footer></app-footer>
    </footer>
</div>