import { Injectable } from '@angular/core';
import {AppConfig} from "../config/app.config";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UsageAlertsHttpService {
  constructor(private appConfig: AppConfig, private http: HttpClient) {}
  getUsageAlerts(accountId: number): Observable<any> {
    const requestUrl = this.appConfig.getApiBaseUrl() + '/last_bill?accountId=' + accountId;
    return this.http.get(requestUrl);
  }
  getBillByAccountIdAndInvoiceId(accountId: number, invoiceId: string): Observable<any> {
    let params = new HttpParams()
        .set('accountId', accountId.toString())
        .set('invoiceId', invoiceId);
    const url = `${this.appConfig.getApiBaseUrl()}/last_bill`;
    return this.http.get(url, { params: params });
  }
}
