import {AfterViewInit, Component, ElementRef, inject, OnDestroy, OnInit, signal, ViewChild} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {AsyncPipe, CurrencyPipe, DecimalPipe, NgIf} from "@angular/common";
import {Header} from "../../../models/header";
import {UsageAlertsHttpService} from "../../../httpServices/usage-alerts.http-service";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {Line} from "../../../models/line";
import {debounceTime, EMPTY, of, Subject, switchMap} from "rxjs";
import {MatOption, MatSelect} from "@angular/material/select";
import {Invoice} from "../../../models/invoice";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {catchError} from "rxjs/operators";
import {MatButton} from "@angular/material/button";
import jspdf from "jspdf";
// @ts-ignore
import domtoimage from 'dom-to-image';

@Component({
    selector: 'app-search',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        MatError,
        NgIf,
        CurrencyPipe,
        AsyncPipe,
        MatIconModule,
        MatProgressSpinner,
        DecimalPipe,
        MatSelect,
        MatOption,
        MatCard,
        MatCardContent,
        MatCardHeader,
        MatCardTitle,
        MatButton
    ],
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
    totalRiders = '';
    totalElectricCharges = '';
    totalCharges = '';
    searchTerm = new FormControl('', [
        Validators.pattern(/^91\d{10}$/),
        Validators.required
    ]);
    private onDestroy = new Subject<void>();
    accountId: number = 0;
    accountNumber = '';
    errorMessage = signal('');
    header: Header = {} as Header;
    lines = signal<Line[]>([]);
    invoices: Invoice[] = [];
    service = inject(UsageAlertsHttpService);
    accountFound = true;
    loadingCompleted = true;
    accountNotFoundExceptionMessage = '';
    @ViewChild('sections', {static: true}) sections!: ElementRef;

    ngOnInit(): void {
        this.searchTerm?.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((value: string | null) => {
                    if (this.searchTerm.valid) {
                        this.accountId = +value!;
                        this.loadingCompleted = false;
                        return this.service.getUsageAlerts(this.accountId)
                            .pipe(
                                catchError(() => {
                                    this.errorMessage.set('Search Term must start with 91 and be 12 characters long');
                                    this.loadingCompleted = true;
                                    this.accountFound = false;
                                    return EMPTY;
                                }));
                    } else {
                        this.errorMessage.set('Search Term must start with 91 and be 12 characters long');
                        this.clearAllDetails();
                        return EMPTY;
                    }
                }),
            )
            .subscribe((result) => {
                    this.accountNumber = this.accountId.toString();
                    this.lines.set(result['lines']);
                    this.header = result['header'][0];
                    this.invoices = result['invoices'].map((data: any[]) => ({
                        month: data[0],
                        invoiceId: data[1],
                    }));
                    this.accountFound = true;
                    this.loadingCompleted = true;
                    this.totalCharges = this.header.bill_new_charge_dollars.toString();
                },
                (e) => {
                    this.accountNotFoundExceptionMessage = e.statusText;
                    this.accountFound = true;
                    this.loadingCompleted = true;
                });
    }

    clearInput() {
        this.searchTerm.setValue('');
        this.clearAllDetails();
    }

    private clearAllDetails() {
        this.header = {} as Header;
        this.lines.set([]);
        this.invoices = [];
        this.totalElectricCharges = '';
        this.totalRiders = '';
        this.totalCharges = '';
        this.loadingCompleted = true;
        this.accountFound = true;
        this.accountNumber = '';
    }

    getCostPerUnit(rider: Line): string {
        if (+rider.billed_quantity !== 0) {
            return (+rider.dollar_amount / +rider.billed_quantity).toFixed(6);
        }
        return '';
    }

    ngAfterViewInit(): void {
        const svg = document.querySelector("path");
        if (svg) {
            svg.setAttribute('transform', 'scale(0.4)');
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }

    selectInvoice(value: Invoice): void {
        this.loadingCompleted = false;
        this.lines.set([]);
        this.totalCharges = '';
        const invoiceParameter = value.month + "-" + value.invoiceId;
        this.service.getBillByAccountIdAndInvoiceId(this.accountId, invoiceParameter)
            .subscribe((value) => {
                    this.lines.set(value['lines']);
                    this.header = value['header'][0];
                    this.accountFound = true;
                    this.loadingCompleted = true;
                    this.totalCharges = this.header.bill_new_charge_dollars.toString();
                },
                (e) => {
                    this.accountNotFoundExceptionMessage = e.statusText;
                    this.accountFound = false;
                    this.loadingCompleted = true;
                });
    }

    getMonth(month: string) {
        return month.substring(0, 4) + '-' + month.substring(4, 6);
    }

    exportToPdf() {
        const node = this.sections.nativeElement;
        const clone = node.cloneNode(true);
        const overflowEle = clone.querySelector('.table-account-container');
        overflowEle.style.overflow = 'visible';
        overflowEle.style.height = `${overflowEle.scrollHeight}px`;
        const message = clone.querySelector('.message');
        if (message) {
            message.remove();
        }
        const buttons = clone.querySelector('.buttons');
        if (buttons) {
            buttons.remove();
        }
        document.body.appendChild(clone);
        domtoimage
            .toJpeg(clone, {
                bgcolor: '#ffffff'
            })
            .then((dataUrl: any) => {
                var img = new Image();
                img.crossOrigin = 'Anonymous';
                img.id = 'getshot';
                img.src = dataUrl;

                img.onload =  () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;
                    const pdfWidth = 200;
                    const aspectRatio = imgWidth / imgHeight;
                    const pdfHeight = pdfWidth / aspectRatio;
                    const pdf = new jspdf();
                    pdf.addImage(dataUrl, 'PNG', 10, 10, pdfWidth, pdfHeight);
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = today.getMonth() + 1;
                    const day = today.getDate();
                    const fileName = this.accountNumber + '-'+ month + '-' + day + '-' + year;
                    pdf.save(`${fileName}.pdf`);
                };

                // Download image file if we want to

                // document.body.appendChild(img);
                // const getshot: any = document.querySelector('#getshot');
                //
                // var a = document.createElement('a');
                // a.href = getshot.src;
                // a.download = 'card.jpeg';
                // a.click();
                // document.body.removeChild(img);

            })
            .catch(function (error: any) {
                console.error('oops, something went wrong!', error);
            }).finally(() => {
            document.body.removeChild(clone);
        });
    }
}
