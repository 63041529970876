import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppConfig } from './config/app.config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import {
  MSALInstanceFactory,
  MSALGuardConfigFactory,
  MSALInterceptorConfigFactory,
} from './auth/config/msal.config';
import { MatInputModule } from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {provideRouter, RouterOutlet} from "@angular/router";
import {routes} from "./app.routes";
import {HeaderComponent} from "./components/header/header.component";
import {SearchComponent} from "./components/main/search/search.component";
import {FooterComponent} from "./components/footer/footer.component";
import {MatIconModule} from "@angular/material/icon";
import {BetInterceptor} from "./services/bet-interceptor.service";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    ),
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    RouterOutlet,
    HeaderComponent,
    SearchComponent,
    FooterComponent,
  ],
  providers: [
    AppConfig,
    provideRouter(routes),
    {
      provide: APP_INITIALIZER,
      useFactory: getUseFactory,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BetInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' }
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
export function getUseFactory(config: AppConfig) {
  return () => config.load();
}
